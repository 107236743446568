type CloudinaryVideoProps = {
  className?: string;
  width?: number;
  aspectRatio?: '16:9' | '4:3' | '3:4';
  cloudinaryId: string;
};

export default function CloudinaryVideo({ className, width = 1000, aspectRatio, cloudinaryId }: CloudinaryVideoProps) {
  const transforms = [
    `f_auto`,
    `q_auto`,
    `c_fill`,
    `ac_none`,
    ...(aspectRatio ? [`ar_${aspectRatio}`, 'c_fill'] : []),
    `w_${width}`
  ]
    .filter(Boolean)
    .join(',');
  return (
    <video
      className={className}
      autoPlay
      src={`https://res.cloudinary.com/dzikyutux/video/upload/${transforms}/${cloudinaryId}`}
      muted
      loop
      controls={false}
    />
  );
}

// https://res.cloudinary.com/dzikyutux/image/upload/v1640224879/Festino/404.gif
export function MissingSomething(props: Omit<CloudinaryVideoProps, 'cloudinaryId'>) {
  return <CloudinaryVideo cloudinaryId="v1647917241/ilagine.com/404_xtzewb.gif" {...props} />;
}

export function Grimmacing(props: Omit<CloudinaryVideoProps, 'cloudinaryId'>) {
  return <CloudinaryVideo cloudinaryId="v1647917241/ilagine.com/404_xtzewb.gif" {...props} />;
}
