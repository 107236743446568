import { clsx } from 'clsx';
import { ArticleCard } from '~/components/article-card.tsx';
import { Grid } from '~/components/grid.tsx';
import { HeaderSection } from '~/components/sections/header-section.tsx';
import { Spacer } from '~/components/spacer.tsx';
import { type MdxListItem } from '~/types.tsx';

interface BlogSectionProps {
  articles: Array<MdxListItem>;
  title: string;
  description: string;
  showArrowButton?: boolean;
}

function BlogSection({ articles, title, description, showArrowButton = true }: BlogSectionProps) {
  return (
    <>
      <HeaderSection
        title={title}
        subTitle={description}
        cta={showArrowButton ? 'Ver el blog completo' : undefined}
        ctaUrl="/blog"
      />
      <Spacer size="2xs" />
      <Grid className="gap-y-16">
        {articles.slice(0, 3).map((article, idx) => (
          <div
            key={article.slug}
            className={clsx('col-span-4', {
              'hidden lg:block': idx >= 2
            })}
          >
            <ArticleCard article={article} />
          </div>
        ))}
      </Grid>
    </>
  );
}

export { BlogSection };
