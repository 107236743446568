import { Link } from '@remix-run/react';
import { clsx } from 'clsx';
import { format, parseISO } from 'date-fns';
import es from 'date-fns/locale/es/index.js';
import { getImageBuilder, getImgProps } from '~/images.tsx';
import { type MdxListItem, type MdxPage } from '~/types.tsx';
import { BlurrableImage } from './blurrable-image.tsx';
import { MissingSomething } from './kifs.tsx';
import { H5 } from './typography.tsx';

function getBannerAltProp(frontmatter: MdxPage['frontmatter']) {
  return (
    frontmatter.bannerAlt ?? frontmatter.bannerTitle ?? frontmatter.bannerCredit ?? frontmatter.title ?? 'Post banner'
  );
}

function getBannerTitleProp(frontmatter: MdxPage['frontmatter']) {
  return frontmatter.bannerTitle ?? frontmatter.bannerAlt ?? frontmatter.bannerCredit;
}

function ArticleCard({
  article: {
    readTime,
    slug,
    frontmatter,
    frontmatter: {
      date = format(new Date(), 'yyyy-MM-ii'),
      title = 'Untitled Post',
      bannerCloudinaryId,
      bannerBlurDataUrl
    }
  }
}: {
  article: MdxListItem;
}) {
  //   const { requestInfo } = useRootData();
  //   const permalink = `${requestInfo.origin}/blog/${slug}`;

  return (
    <div className={clsx('relative w-full')}>
      <Link
        prefetch="intent"
        className="group peer relative block w-full rounded-lg bg-rose-100 focus:outline-none dark:bg-gray-800"
        to={`/blog/${slug}`}
      >
        {bannerCloudinaryId ? (
          <BlurrableImage
            key={bannerCloudinaryId}
            blurDataUrl={bannerBlurDataUrl}
            className="aspect-h-4 aspect-w-3 rounded-lg"
            img={
              <img
                title={frontmatter.title ?? getBannerTitleProp(frontmatter)}
                {...getImgProps(getImageBuilder(bannerCloudinaryId, getBannerAltProp(frontmatter)), {
                  widths: [280, 560, 840, 1100, 1300, 1650],
                  sizes: [
                    '(max-width:639px) 80vw',
                    '(min-width:640px) and (max-width:1023px) 40vw',
                    '(min-width:1024px) and (max-width:1620px) 25vw',
                    '420px'
                  ],
                  transformations: {
                    background: 'rgb:e6e9ee',
                    resize: {
                      type: 'fill',
                      aspectRatio: '3:4'
                    }
                  }
                })}
                className="focus-ring w-full rounded-lg object-cover object-center transition"
              />
            }
          />
        ) : (
          <div className="aspect-h-4 aspect-w-3">
            <div className="focus-ring w-full rounded-lg transition">
              <MissingSomething aspectRatio="3:4" />
            </div>
          </div>
        )}

        <H5 as="div" className="mt-4 px-4 font-medium">
          {title}
        </H5>
        <div className="p-4 text-lg text-zinc-500 dark:text-white">
          {format(parseISO(date), 'PPP', { locale: es })} —{' '}
          {readTime?.minutes ? `${Math.ceil(readTime.minutes)} min. de lectura` : 'Lectura rápida'}
        </div>
      </Link>
    </div>
  );
}

export { ArticleCard };
